import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/vercel/workpath0/packages/swarm-docs/src/components/layout-mdx.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Spacing`}</h1>
    <br />
Swarm uses a 4-base grid scale. The grig provides values in intervals of 4 with a base space being defined as 16.
    <br />
    <table className="props-table">
    <tr>
        <th>Size Variable</th>
        <th>Size (px)</th>
        <th>CSS Variable</th>
    </tr>
    <tr>
        <td className="text-code">0</td>
        <td>4px</td>
        <td>--size-space-0</td>
    </tr>
    <tr>
        <td className="text-code">1</td>
        <td>4px</td>
        <td>--size-space-1</td>
    </tr>
    <tr>
        <td className="text-code">2</td>
        <td>8px</td>
        <td>--size-space-2</td>
    </tr>
    <tr>
        <td className="text-code">3</td>
        <td>12px</td>
        <td>--size-space-3</td>
    </tr>
    <tr>
        <td className="text-code">4</td>
        <td>16px</td>
        <td>--size-space-4</td>
    </tr>
    <tr>
        <td className="text-code">5</td>
        <td>20px</td>
        <td>--size-space-5</td>
    </tr>
    <tr>
        <td className="text-code">6</td>
        <td>24px</td>
        <td>--size-space-6</td>
    </tr>
    <tr>
        <td className="text-code">7</td>
        <td>28px</td>
        <td>--size-space-7</td>
    </tr>
    <tr>
        <td className="text-code">8</td>
        <td>32px</td>
        <td>--size-space-8</td>
    </tr>
    <tr>
        <td className="text-code">9</td>
        <td>36px</td>
        <td>--size-space-9</td>
    </tr>
    <tr>
        <td className="text-code">10</td>
        <td>40px</td>
        <td>--size-space-10</td>
    </tr>
    <tr>
        <td className="text-code">11</td>
        <td>44px</td>
        <td>--size-space-11</td>
    </tr>
    <tr>
        <td className="text-code">12</td>
        <td>48px</td>
        <td>--size-space-12</td>
    </tr>
    <tr>
        <td className="text-code">13</td>
        <td>52px</td>
        <td>--size-space-13</td>
    </tr>
    <tr>
        <td className="text-code">14</td>
        <td>56px</td>
        <td>--size-space-10</td>
    </tr>
    <tr>
        <td className="text-code">15</td>
        <td>60px</td>
        <td>--size-space-15</td>
    </tr>
    <tr>
        <td className="text-code">16</td>
        <td>64px</td>
        <td>--size-space-16</td>
    </tr>
    </table>
    <br />
    <br />
    <p><strong parentName="p">{`Application`}</strong></p>
    <p>{`Swarm spacing is built into all atoms of the design system, like Buttons and Text Inputs. To assemble layouts, spacing tokens can be applied to all four edges of a DOM element, using margin and padding properties.`}</p>
    <br />
    <br />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      